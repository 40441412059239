import axios from './index'

export default {
  GetBankInfo(bankInfoId) {
    return axios.post("/BankInfo/Data", { id: bankInfoId })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  submitNewBankInfo(model) {
    return axios.post("/BankInfo/Upsert", model)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  GetBankInfoTypeList() {
    return axios.get('/data/accounting/bankInfoType')
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },
}
