<template>
  <b-container fluid>
    <ValidationObserver ref="bankInfoObserver" v-slot="{ invalid }">
      <form>
        <b-row>
          <b-form-group class="col-md-6" :label="$t('Name')" label-for="name">
            <ValidationProvider :name="$t('Name')" rules="required" v-slot="{ errors }">
              <b-form-input :class="(errors.length > 0 ? ' is-invalid' : '')" v-model="model.name " type="text"></b-form-input>
              <b-form-invalid-feedback>{{ $t('ValidationMessage.CannotBeEmpty') }}</b-form-invalid-feedback>
            </ValidationProvider>
          </b-form-group>
          <b-form-group class="col-md-6" :label="$t('MainCategory')" label-for="showInCalendar">
            <v-select transition="" v-model="model.bankInfoTypeID"
                      :reduce="s => Number(s.id)"
                      label="name"
                      :clearable="false"
                      :options="bankInfoTypeList"><template v-slot:no-options> {{$t('NoMatchingOptionsMessage')}} </template> </v-select>
          </b-form-group>
          <b-form-group class="col-md-12" :label="$t('Notes')" label-for="displayOrder">
            <b-textarea class="form-control"
                        v-model="model.notes" />
          </b-form-group>
        </b-row>
        <modal-footer-button :set-is-submitting="isSubmitting"
                             @submit="submitNewBankInfo"></modal-footer-button>
      </form>
    </ValidationObserver>
  </b-container>
</template>
<script>
  import { refreshSmartDuoData } from '../../../helpers/dataRefresher'
  import bankInfoService from '../../../services/bankInfo';
  import ModalFooterButton from '../../../components/shared/ModalFooterButton'
  export default {
    name: 'BankInfo',
    components: {
      ModalFooterButton
    },
    props: {
      bankInfoId: String
    },
    data() {
      return {
        model: {
          id: '',
          name: '',
          notes: '',
          bankInfoTypeID: null,
        },
        bankInfoTypeList: [],
        isSubmitting: false
      }
    },
    methods: {
      GetBankInfoTypeList() {
        bankInfoService.GetBankInfoTypeList()
          .then((response) => {
            this.bankInfoTypeList = response;
            this.bankInfoTypeList = this.bankInfoTypeList.filter(z => z.id == 1 || z.id == 2 || z.id == 3 || z.id == 4 || z.id == 5 || z.id == 9 || z.id == 11);
            if (!this.model.id || this.model.id.length == 0) {
              if (this.bankInfoTypeList && this.bankInfoTypeList.length > 0) {
                this.model.bankInfoTypeID = this.bankInfoTypeList[0].id;
              }
            }
          });
      },
      GetBankInfo: function () {
        bankInfoService.GetBankInfo(this.model.id).then(
          (result) => {
            if (result != null) {
              this.model = result;
            } else {
              this.$toastr.error(this.$t("Error"));
            }
          }
        )
      },
      async submitNewBankInfo() {
        const isValid = await this.$refs.bankInfoObserver.validate();
        if (!isValid) {
          this.$toastr.error(this.$t('ValidationMessage.EmptyAndValidateError'));
          return;
        }

        this.isSubmitting = true;

        bankInfoService.submitNewBankInfo(this.model).then((result) => {
          this.isSubmitting = false;
          if (result) {
            this.$emit('closeModal');
            this.$toastr.success(this.$t('Success'));
            refreshSmartDuoData();
          } else {
            this.$toastr.error(this.$t('Error'));
          }
        }).finally(() => { this.isSubmitting = false; });
      },
    },
    watch: {
      bankInfoTypeID: function (val) {
        return val.toString();
      },
    },
    mounted: function () {
      this.model.id = this.bankInfoId;

      this.GetBankInfoTypeList();

      if (this.model.id && this.model.id.length > 0) {
        this.GetBankInfo();
      }
    },
  }
</script>
